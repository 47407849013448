import React, { useState, useEffect } from 'react';
import '../styles/Banner.css';
import flexcellence from '../assets/Banner/Flexcellence.jpg';
import medical_benfits from '../assets/Banner/medical_benfits.jpg';
import fitness_ai from '../assets/Banner/fitness_ai.jpg';

const slides = [
  { image: flexcellence, heading: 'Flexcellence', tagline: 'Flex to fit nourish to thrive' },
  { image: fitness_ai, heading: 'Transform Your Health with Flexcellence', tagline: 'Elevate Your Fitness with AI-Guided Workouts and Tailored Nutrition Plans!' },
  { image: medical_benfits, heading: 'Medical Benefits', tagline: 'Flexcellence isn’t just about fitness. Our plans go beyond fitness, addressing medical concerns, improving key health markers, and preventing future health issues.' },
];

const Banner = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [textAnimation, setTextAnimation] = useState(false);

  const nextSlide = () => {
    setTextAnimation(true);
    setTimeout(() => {
      setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
      setTextAnimation(false);
    }, 500);
  };

  const prevSlide = () => {
    setTextAnimation(true);
    setTimeout(() => {
      setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
      setTextAnimation(false);
    }, 500);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => clearInterval(interval);
  }, [currentSlide]);

  return (
    <div className="banner-section">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`slide ${index === currentSlide ? 'active' : ''}`}
          style={{ backgroundImage: `url(${slide.image})` }}
        >
          {index === currentSlide && (
            <div className={`text-container ${textAnimation ? 'animate-out' : 'animate-in'}`}>
              <h2 className="heading">{slide.heading}</h2>
              <p className="tagline">{slide.tagline}</p>
            </div>
          )}
        </div>
      ))}
      <button className="prev-button" onClick={prevSlide}>❮</button>
      <button className="next-button" onClick={nextSlide}>❯</button>
    </div>
  );
};

export default Banner;
