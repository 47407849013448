import React, { useState } from 'react';
import '../styles/AboutUs.css';
import consultationIcon from '../assets/Consultation/consultation.png';

const AboutUs = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Get form values
    const formData = {
      firstName: e.target.elements[0].value,
      lastName: e.target.elements[1].value,
      weight: parseFloat(e.target.elements[2].value),
      height: parseFloat(e.target.elements[3].value),
      age: parseInt(e.target.elements[4].value),
      email: e.target.elements[5].value,
      phoneNumber: e.target.elements[6].value
    };
  
    // Validate required fields
    const requiredFields = Object.keys(formData);
    const emptyFields = requiredFields.filter(field => !formData[field]);
    
    if (emptyFields.length > 0) {
      alert(`Please fill in all required fields: ${emptyFields.join(', ')}`);
      return;
    }
  
    try {
      const response = await fetch('http://localhost:8000/api/consultation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });
  
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.detail || 'Something went wrong');
      }
  
      alert('Consultation request submitted successfully!');
      closeModal();
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <section className="about-us-section flex flex-col lg:flex-row justify-center items-center py-12 px-6 bg-white">
      <div className="container flex flex-col lg:flex-row justify-between w-full">

        {/* Left Column (2/3rd) */}
        <div className="left-column lg:w-2/3 lg:pr-12 mb-8 lg:mb-0">
          <p className="italic text-gray-500 text-lg md:text-xl mb-4">We are team Flexcellence,</p>
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4">
            Our PASSION LED US <br /> BECOMING FITNESS TRAINER
          </h2>
          <p className="about-heading text-xl md:text-2xl font-medium mb-4">
            “Achieve Lasting Health with Flexcellence”
          </p>
          <p className="text-gray-600 text-md md:text-xl leading-relaxed mb-6 text-justify">
            At Flexcellence, we understand that health is the cornerstone of a fulfilling life. Our tailor-made plans are designed to transform your health and fitness in a sustainable way, helping you unlock your full potential. Whether you're aiming to get fit, manage a medical condition, or elevate your overall well-being, Flexcellence offers a personalized approach with all the support you need.
          </p>
          <div className="signature">
            <p className="font-signature text-xl md:text-2xl">- Flexcellence Team</p>
          </div>
        </div>

        {/* Right Column (1/3rd) */}
        <div className="right-column lg:w-1/3 flex justify-center">
          <div className="card bg-gray-100 p-6 md:p-10 rounded-lg shadow-lg text-left relative overflow-hidden">
            <img src={consultationIcon} alt="Consultation" className="h-24 w-24 md:h-32 md:w-32 mb-4" />
            <h3 className="text-2xl md:text-3xl font-semibold mb-2">Consultation</h3>
            <p className="text text-gray-600 mb-4 text-justify text-sm md:text-lg">
              The great explorers truth builders human happiness because of pleasure.
            </p>
            <button className="font-bold text-lg text-green-600" onClick={openModal}>CONSULT WITH ME</button>
          </div>
        </div>
      </div>

      {/* Modal for Consultation Form */}
      {showModal && (
        <div className="modal-overlay flex items-center justify-center fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50">
          <div className="modal bg-white p-8 rounded-lg shadow-lg w-full max-w-md relative overflow-y-auto"> {/* Added overflow-y-auto */}
              <h2 className="text-xxl font-semibold mb-4 text-center">Consultation Form</h2>
            <br/>
            <form onSubmit={handleSubmit}>
              {/* First Name and Last Name in two columns */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2">First Name</label>
                  <input type="text" className="w-full p-2 border border-gray-300 rounded" placeholder="Enter your first name" />
                </div>
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2">Last Name</label>
                  <input type="text" className="w-full p-2 border border-gray-300 rounded" placeholder="Enter your last name" />
                </div>
              </div>

              {/* Weight and Height in two columns */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2">Weight  (in kg)</label>
                  <input type="number" className="w-full p-2 border border-gray-300 rounded" placeholder="Enter your weight" />
                </div>
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2">Height (in cm)</label>
                  <input type="number" className="w-full p-2 border border-gray-300 rounded" placeholder="Enter your height" />
                </div>
              </div>

              {/* Age */}
              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2">Age</label>
                <input type="number" className="w-full p-2 border border-gray-300 rounded" placeholder="Enter your age" />
              </div>

              {/* Email */}
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Email</label>
                <input type="email" className="w-full p-2 border border-gray-300 rounded" placeholder="Enter your email" />
              </div>

              {/* Phone Number */}
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Phone Number</label>
                <input type="tel" className="w-full p-2 border border-gray-300 rounded" placeholder="Enter your phone number" />
              </div>

              {/* Buttons */}
              <div className="flex justify-between">
                <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded" >Submit</button>
                <button type="button" className="bg-gray-500 text-white px-4 py-2 rounded" onClick={closeModal}>Cancel</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </section>
  );
};

export default AboutUs;
