// src/components/Testimonials.jsx
import React, { useState, useEffect } from 'react';
import '../styles/Testimonials.css';
import icon_testimonials from '../assets/Testimonials/icon_testimonials.png';
import Harihar from '../assets/Testimonials/Harihar.png';
import Pavan from '../assets/Testimonials/Pavan.jpeg';
import hari from '../assets/Testimonials/hari.jpeg';
import haritha from '../assets/Testimonials/haritha.jpg';
import hemanth from '../assets/Testimonials/hemanth.jpeg';
import pranavi from '../assets/Testimonials/pranavi.jpg';
import Soundarya from '../assets/Testimonials/Soundarya.jpeg';
import Vikas from '../assets/Testimonials/Vikas.jpeg';
// import ss_img2 from '../assets/pc_2.jpg';
// import ss_img3 from '../assets/pc_3.jpeg';
// import ss_img4 from '../assets/pc_4.jpeg';

const Testimonials = () => {
  const testimonialsData = [
    {
      image: Harihar,
      text: "Flexcellence helped me get back into shape. Their customised exercise and diet plan help me reduce my weight constantly and consistently from 91kgs to 76 kgs over a period of 8 months. They were very responsive for all the doubts and also constant followups were made to ensure that target is being achieved. They helped my motto of achieving a healthy life style.",
      name: 'Harihar',
      role: 'Software Engineer',

    },
    {
      image: Pavan,
      text: "As a software engineer, I spend long hours sitting at my desk, and staying active has always been a challenge. Flexcellence has been a game changer for me. Their personalized approach to fitness helped me transform from fat to fit in a way that fit perfectly with my busy schedule. The expert guidance kept me motivated, and the structured workout plans made it easy to stay on track. I’ve never felt more confident and energized. Flexcellence didn’t just help me lose weight; it gave me a sustainable lifestyle change that works for my hectic routine.",
      name: 'Pavan',
      role: 'Software engineer',
    },
    {
      image: haritha,
      text: "As someone with a hectic schedule living in the USA, finding the time and energy to focus on my strength and fitness goals felt impossible. Flexcellence provided me with the perfect solution. Their flexible approach allowed me to incorporate effective strength training into my busy routine without feeling overwhelmed. The customized plans helped me focus on building proper strength and shaping up, even with my time constraints. I’ve finally found a program that understands my needs and helps me achieve my fitness goals, while balancing my demanding lifestyle.",
      name: 'Haritha',
      role: 'Global User',
    },
    {
      image: pranavi,
      text: "With my tight work schedule as a software engineer at Deloitte, maintaining a healthy diet and staying fit seemed impossible. Flexcellence has completely transformed how I approach my nutrition and fitness. The tailored meal plans made it easy to follow a healthy diet without requiring hours of meal prep, which was crucial given my limited time. I’ve learned how to balance my meals and stay on track even during the busiest workdays. Their program made a significant difference in my energy levels and overall fitness, and it fits perfectly into my demanding lifestyle.",
      name: 'Pranavi',
      role: 'Software Engineer at Deloitte',
    },
    {
      image: Soundarya,
      text: "Living with PCOD and irregular periods while juggling a stressful workload was really tough for me. I felt drained and didn’t know where to start with my health. Flexcellence changed that. Their support has been so personal and understanding. They created a diet and workout plan that wasn’t just about losing weight—it was about feeling better and managing my symptoms. I’ve noticed such a difference in my energy and mood, and for the first time, I feel in control of my body. It’s like they really understood what I needed, and that has made all the difference.",
      name: 'Soundarya',
      role: 'Engineer',

    },
    {
      image: Vikas,
      text: "At 120 kg and dealing with asthma, losing weight seemed impossible. Flexcellence gave me a realistic path forward. Their personalized approach helped me make real progress, and I finally feel healthier and more confident.",
      name: 'Vikas',
      role: 'Engineer',
    },
    {
      image: hemanth,
      text: "I wanted a six-pack, and Flexcellence got me there fast. Their plan was spot-on, and I saw results quicker than I imagined.",
      name: 'hemanth',
      role: 'Business',
    },
    {
      image: hari,
      text: "As a software engineer from Nepal, balancing work and fitness was tough. Flexcellence made it easier with a plan that fit my schedule and helped me stay on track. I feel stronger and more energized than ever.",
      name: 'Hari',
      role: 'Software Engineer, Nepal',
    },
  ];

  const [current, setCurrent] = useState(0);

  const nextTestimonial = () => {
    setCurrent((current + 1) % testimonialsData.length);
  };

  const prevTestimonial = () => {
    setCurrent((current - 1 + testimonialsData.length) % testimonialsData.length);
  };

  useEffect(() => {
    const interval = setInterval(nextTestimonial, 5000); // Change testimonial every 5 seconds
    return () => clearInterval(interval); // Cleanup on unmount
  }, [current]); // Dependency on current so it updates when the current testimonial changes

  return (
    <div className="testimonial-container">
      <div className="testimonial-card">
        <div className="testimonial-left">
          <img src={testimonialsData[current].image} alt={`Testimonial by ${testimonialsData[current].name}`} />
        </div>
        <div className="testimonial-right">
          <div className="testimonial-header">
            <img src={icon_testimonials} alt="Feedback icon" className="feedback-icon" />
            <div>
              <p className="feedback-title">Feedback</p>
              <h2>TESTIMONIALS</h2>
            </div>
          </div>

          <div className="testimonial-feedback">
            <p>{testimonialsData[current].text}</p>
          </div>

          <div className="testimonial-footer">
            <div className="testimonial-name-role">
              <h3>{testimonialsData[current].name}</h3>
              <p className="testimonial-role">{testimonialsData[current].role}</p>
            </div>
            <div className="testimonial-arrows">
              <button onClick={prevTestimonial} className="arrow-button">&lt;</button>
              <button onClick={nextTestimonial} className="arrow-button">&gt;</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
