// src/components/Home.jsx
import React from 'react';
import AboutUs from './AboutUs';
import Services from './Services';
import InfoSection from './InfoSection';
import Features from './Features';
import Testimonials from './Testimonials';
import ContactSection from './ContactSection';
import Banner from './Banner';

const Home = () => {
  return (
    <main className="w-full overflow-x-hidden"> {/* Add padding-top to account for fixed header */}
      <section id="banner" className="w-full h-screen flex items-center justify-center">
        {/* <h2 className="text-3xl">About Us</h2> */}
        < Banner/>
      </section>
      <section id="about" className="w-full min-h-screen flex items-center justify-center">
        {/* <h2 className="text-3xl">About Us</h2> */}
        <AboutUs/>
      </section>
      <section id="services" className="w-full min-h-screen flex items-center justify-center">
        {/* <h2 className="text-3xl">Services</h2> */}
        <Services/>
      </section>
      <section id="info" className="w-full min-h-screen flex items-center justify-center">
        {/* <h2 className="text-3xl">Info</h2> */}
        <InfoSection/>
      </section>
      <section id="features" className="w-full min-h-screen flex items-center justify-center">
        {/* <h2 className="text-3xl">Features</h2> */}
        <Features/>
      </section>
      <section id="testimonials" className="w-full min-h-screen flex items-center justify-center">
        {/* <h2 className="text-3xl">Testimonials</h2> */}
        <Testimonials/>
      </section>
      <section id="contact" className="w-full flex items-center justify-center ">
        {/* <h2 className="text-3xl">Contact</h2> */}
        <ContactSection/>
      </section>
    </main>
  );
};

export default Home;
