import React from 'react';
import '../styles/InfoSection.css';
import icon_accountability from '../assets/InfoSection/accountability.jpg'; 
import icon_medical from '../assets/InfoSection/medical.jpg';
import icon_progress from '../assets/InfoSection/icon_progress.jpg';
import icon_wellness from '../assets/InfoSection/wellness.jpg'; 
import InfoPoint from '../components/InfoPoint';

const infoData = [
  {
    icon: icon_accountability,
    heading: 'Effortless Accountability',
    details: 'Our coaches will help keep you on track and motivated to ensure you stay committed to your goals.',
  },
  {
    icon: icon_wellness,
    heading: 'Complete Wellness Support',
    details: 'We provide a holistic solution, from customized meal plans to fitness routines that cater to your unique body type and needs.',
  },
  {
    icon: icon_progress,
    heading: 'Progress Tracking',
    details: 'Our app tracks everything, including steps, calories, sleep, and hydration, providing real-time insights for better decision-making.',
  },
  {
    icon: icon_medical,
    heading: 'Medical Benefits',
    details: 'Flexcellence isn’t just about fitness. Our plans go beyond fitness, addressing medical concerns, improving key health markers, and preventing future health issues.',
  },
  
];

const InfoSection = () => {
  return (
    <div className="info-section-container">
      <div className="info-image"></div> {/* Left half for the image */}
      <div className="info-points-container">
        {infoData.map((point, index) => (
          <InfoPoint 
            key={index} 
            icon={point.icon} 
            heading={point.heading} 
            details={point.details} 
          />
        ))}
      </div>
    </div>
  );
};

export default InfoSection;

