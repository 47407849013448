import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-scroll';
import '../styles/Header.css';
import main_logo from '../assets/logo-short.png';

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = (e) => {
    e.stopPropagation();
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && !event.target.closest('.hamburger')) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="header">
      <div className="container">
        <div className="logo">
          <img src={main_logo} alt="Logo" className="logo-img" />
          <h1 className="logo-title">Flexcellence</h1>
        </div>

        <nav ref={menuRef}>
          <ul className={`nav-list ${isMenuOpen ? 'active' : ''}`}>
            <li>
              <Link to="banner" smooth={true} className="nav-link" onClick={toggleMenu}>Home</Link>
            </li>
            <li>
              <Link to="about" smooth={true} className="nav-link" onClick={toggleMenu}>About Us</Link>
            </li>
            <li>
              <Link to="services" smooth={true} className="nav-link" onClick={toggleMenu}>Services</Link>
            </li>
            <li>
              <Link to="info" smooth={true} className="nav-link" onClick={toggleMenu}>Info</Link>
            </li>
            <li>
              <Link to="features" smooth={true} className="nav-link" onClick={toggleMenu}>Features</Link>
            </li>
            <li>
              <Link to="testimonials" smooth={true} className="nav-link" onClick={toggleMenu}>Testimonials</Link>
            </li>
            <li>
              <Link to="contact" smooth={true} className="nav-link" onClick={toggleMenu}>Contact</Link>
            </li>
          </ul>
        </nav>

        {/* Hamburger menu */}
        <div className="hamburger" onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>

      </div>
    </header>
  );
};

export default Header;
