// src/components/Services.jsx
import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import PlanCard from '../components/PlanCard';
import '../styles/Services.css';
import flex_diet from '../assets/Plans/flex_diet.jpg'; 
import flex_elite from '../assets/Plans/flex_elite.jpg';
import flex_fitness from '../assets/Plans/flex_fitness.jpg';
import flex_medi from '../assets/Plans/flex_medi.jpg';
import { API_BASE_URL } from '../utils/Constants';


//ToDo : after fetching , add image into array.



const Services = () => {

  const cardWrapperRef = useRef(null);
  const [scrollAmount, setScrollAmount] = useState(0);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/plans`);
        const fetchedData = response.data;   
        console.log('data',fetchedData);
        const plansWithImages = fetchedData.map(plan => ({
          ...plan,
          image:  flex_fitness || '', // Add image to each plan
        }));
        setPlans(plansWithImages);
      } catch (error) {
        console.error('Error fetching plans:', error);
      }
    };

    fetchPlans();
  }, []);

  useEffect(() => {
    cardWrapperRef.current.scrollLeft = 0; // Ensure it starts at the beginning
    }, []);



  // Scroll left function
  const scrollLeft = () => {
    const cardWidth = cardWrapperRef.current?.firstChild?.offsetWidth;
    cardWrapperRef.current.scrollLeft -= cardWidth; // Adjust based on the width of your cards
  };

  // Scroll right function
  const scrollRight = () => {
    const cardWidth = cardWrapperRef.current?.firstChild?.offsetWidth;
    cardWrapperRef.current.scrollLeft += cardWidth; // Adjust based on the width of your cards
  };

  return (
    <div className="services-container">
      <div>
      <h2 className="services-title">Our Plans</h2>
      </div>
      <div className="services-cards-container" ref={cardWrapperRef}>
        <div className="service-cards-wrapper">
          {plans.map((service, index) => (
            <PlanCard 
              key={index} 
              image={service.image} 
              planName={service.planname} 
              duration={service.duration} 
              price={service.price} 
              planDetails = {service.plandetails}
            />
          ))}
        </div>
      </div>

      {/* Arrows for sliding effect */}
      <div className="arrows">
        <button className="left-arrow" onClick={scrollLeft}>❮</button>
        <button className="right-arrow" onClick={scrollRight}>❯</button>
      </div>
    </div>
  );
};

export default Services;
