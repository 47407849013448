// src/components/InfoPoint.jsx
import React from 'react';

const InfoPoint = ({ icon, heading, details }) => {
  return (
    <div className="info-point">
      <img src={icon} alt="icon" className="info-icon" />
      <div className="info-text">
        <h3 className="info-heading">{heading}</h3>
        <p className="info-details">{details}</p>
      </div>
    </div>
  );
};

export default InfoPoint;
