// src/components/PlanCard.jsx
import React from 'react';
import '../styles/PlanCard.css';
import { useState } from 'react';

const PlanCard = ({ image, planName, duration, price , planDetails }) => {
  const [isHovered, setIsHovered] = useState(false);
  // console.log('details',planDetails);
  return (
    <div className="plan-card"
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
    >
      {/* Image section */}
      <img src={image} alt={planName} className="plan-card-image" />

      {/* Price Tag */}
      <div className="plan-card-price">₹ {Math.round(price / duration)} / month</div>

      {/* Bottom overlay section */}
      <div className="plan-card-details">
        <div className="plan-card-title">{planName}
        </div>
        <hr className="divider" />
        <div className="plan-card-duration">{duration} Classes / Month</div>
      </div>

      {/* Additional details overlay */}
      {true && (
        <div className="plan-card-overlay">
          <h4 className="overlay-title">Plan Details</h4>
          {planDetails.map((plan)=> (
            <div>{plan}</div>
          ))}
        </div>
      )}

    </div>
  );
};

export default PlanCard;
