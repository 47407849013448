// src/components/PuzzleImages.jsx
import React from 'react';
import '../styles/Features.css'; // Style this layout accordingly

import img_24_7 from '../assets/Features/24_7.jpg';
import img_All_in_one_wellness from '../assets/Features/All_in_one_wellness.jpg';
import img_Expert_coaches from '../assets/Features/Expert_coaches.jpg';
import img_no_cost_emi from '../assets/Features/no_cost_emi.jpg';
import img_pause_any_time from '../assets/Features/pause_any_time.jpg';
import img_personalized_plan from '../assets/Features/personalized_plan.jpg';
import img_real_time_support from '../assets/Features/real_time_support.jpg';


const puzzleData = [
  { image: img_All_in_one_wellness, text: 'All-in-One Wellness' },
  { image: img_pause_any_time, text: 'Pause Anytime' },
  { image: img_Expert_coaches, text: 'Expert Coaches' },
  { image: img_no_cost_emi, text: 'No-Cost EMI ' },
  { image: img_personalized_plan, text: 'Personalized Plans' },
  { image: img_real_time_support, text: 'Real-Time Support' },
  { image: img_24_7, text: '24/7 support' }
];

const Features = () => {
  return (
    <div className="puzzle-container">
      {puzzleData.map((item, index) => (
        <div key={index} className={`puzzle-item item-${index + 1}`}>
          <img src={item.image} alt={`puzzle-${index}`} />
          <div className="overlay-text">{item.text}</div>
        </div>
      ))}
    </div>
  );
};

export default Features;
