import React from 'react';
import main_logo from '../assets/logo-short.png';
import { Link } from 'react-scroll';

const ContactSection = () => {
  return (
    <footer className="bg-[#2c3e50] text-white py-12 w-full">
      <div className="w-full mx-auto grid grid-cols-1 md:md:grid-cols-[70%,30%] gap-8 px-6">

        {/* Left Column: Logo and Description */}
        <div className="px-10">
          <div className="flex items-center mb-4">
            <img src={main_logo} alt="Logo" className="w-10 h-10 mr-2" />
            <h3 className="text-xl font-bold">Flexcellence</h3>
          </div>
          <p className="text-sm mb-6">
            Start today and witness sustainable health changes like reduced lifestyle-related medical concerns, weight management, and improved fitness. Flexcellence is here to guide you every step of the way!
          </p>
          <div className="flex space-x-2">
            <button className="bg-[#ff6347] text-white px-4 py-2 rounded flex items-center">
              <i className="fab fa-google-play mr-2"></i> Get it on PlayStore
            </button>
            <button className="bg-[#ff6347] text-white px-4 py-2 rounded flex items-center">
              <i className="fab fa-apple mr-2"></i> Get it on Apple Store
            </button>
          </div>
        </div>

        {/* Right Column: Contact Details */}
        <div className="px-10">
          <h4 className="font-bold text-lg mb-6">CONTACT US</h4>
          <p className="text-sm mb-6">
            <b>Flexcellence wellness PVT.LTD</b> <br />
            Maruthi Residency, <br />
            Block 2, Flat no 102, <br />
            Kompally, Hyderabad, <br />
            Andhra Pradesh - 500100, IN
          </p>
          <p className="mt-4">
            Email: <a href="mailto:support@example.com" className="hover:underline text-[#ff6347]">support@flexcellence.co</a><br />
            Phone: +91 97015 10768
          </p>
          <div className="flex space-x-4 mt-4">
            <a href="#"><i className="fab fa-facebook text-white hover:text-[#ff6347]"></i></a>
            <a href="#"><i className="fab fa-twitter text-white hover:text-[#ff6347]"></i></a>
            <a href="#"><i className="fab fa-linkedin text-white hover:text-[#ff6347]"></i></a>
            <a href="#"><i className="fab fa-skype text-white hover:text-[#ff6347]"></i></a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default ContactSection;
